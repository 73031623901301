<template>
  <div
    class="form-builder__input"
    :class="schema.styleClasses || ''"
  >
    <q-input
      :standout="schema.standout || (schema.color ? undefined : 'bg-teal text-white')"
      class="full-width"
      :filled="!schema.noFilled"
      :color="schema.color || undefined"
      :dark="$q.dark.isActive"
      :model-value="(schema.get && schema.get(schema.value)) || schema.value"
      :disable="!!schema.disabled"
      :step="!!schema.step"
      :required="!!schema.required"
      :label="`${schema.label} ${schema.required ? '*' : ''}`"
      :placeholder="schema.placeholder || ''"
      :hide-bottom-space="!schema.hint"
      :hint="schema.hint"
      :type="schema.inputType || 'text'"
      :dense="!!schema.dense"
      :input-class="{ 'date-field': schema.inputType === 'date' || schema.inputType === 'time' }"
      :debounce="schema.debounce || 0"
      :autocomplete="schema.autocomplete || ''"
      :rules="schema.rules"
      :error-message="error"
      :error="!!error"
      @change="handleChange"
      @update:model-value="handleInput"
      @focus="handleFocus"
      @blur="handleFocusOut"
    >
      <template v-if="schema.icon" v-slot:prepend>
        <q-icon :name="schema.icon" :color="schema.iconColor" />
      </template>

      <template v-if="schema.value" v-slot:append>
        <q-icon name="close" @click="reset" class="cursor-pointer" :color="schema.iconColor" />
      </template>
    </q-input>
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    error () {
      return this.validate(this.schema.value) || this.schema.error
    }
  },
  methods: {
    reset () {
      this.handleChange('')
      this.handleInput('')
    },
    validate (text) {
      if (this.schema.max && `${text}`.length > this.schema.max) {
        return `Please use maximum ${this.schema.max} characters`
      }

      return ''
    },
    handleChange (e) {
      if (this.schema.onChange) {
        const value = this.schema.inputType === 'number'
          ? Number(e)
          : e

        this.schema.onChange(value, this.schema.value)
      }
    },
    handleInput (e) {
      if (this.schema.onInput) {
        let value
        if(!this.schema.isNotNumber){
          value = this.schema.inputType === 'number'
          ? Number(e)
          : e
        }else {
          value = e
        }
        

        this.schema.onInput(value, this.schema.value)
      }
    },
    handleFocus (e) {
      if (this.schema.onFocus) {
        this.schema.onFocus(e)
      }
    },
    handleFocusOut (e) {
      if (this.schema.onFocusOut) {
        this.schema.onFocusOut(e)
      }
    }
  }
}
</script>
